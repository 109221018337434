<template>
  <div v-if="timetables != undefined">
    <!-- fix para el fallo de variable is undefined-->
    <loading-component></loading-component>
    <v-main class="page-timetables">
      <v-container fluid fill-height>
        <v-layout justify-center align-center>
          <v-flex xs12 sm10>
            <v-container id="container-timetables">
              <div class="container-timetable">
                <h1 class="timetable-port">{{ timetables.alias }}</h1>
                <div v-if="timetables.timetables.length > 0" class="timetables-body">
                  <div
                    class="timetable"
                    v-for="(timetable, index) in timetables.timetables"
                    :key="index"
                  >
                    <h1 class="timetable-type">{{ timetable.type }}</h1>
                    <p
                      class="timetable-time"
                      v-html="formatTime(timetable.time)"
                    ></p>
                    <v-divider
                      v-if="index != timetables.timetables.length - 1"
                      class="timetable-hr"
                    />
                  </div>
                </div>
                <div v-else class="no_timetables">
                  <p>Sin Horarios</p>
                </div>
              </div>
            </v-container>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import LoadingComponent from '../components/LoadingComponent.vue'
export default {
  name: 'timetables-ports',
  components: {
    LoadingComponent
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      show: false,
      title: 'Horarios',
      timetables: undefined,
      port: undefined
    }
  },
  methods: {
    formatTime: function (text) {
      return text.replace('\r\n', '<br>')
    },

    customDateFormat (day, month, year) {
      var date = '0/00/0000'
      var mesesAbreviados = [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic'
      ]
      var currentYear = new Date().getFullYear()
      if (year === currentYear.toString()) {
        date = day + ' ' + mesesAbreviados[month - 1] + '.'
      } else {
        date = day + '/' + month + '/' + year.slice(2, 4)
      }
      return date
    },
    asignAvatarColor (userId) {
      var colors = [
        '#FF4E00',
        '#6783b6',
        '#397367',
        '#ff66b3',
        '#2c6e49',
        '#222e50',
        '#463239',
        '#721121',
        '#9d8420',
        '#b8336a'
      ]
      return colors[userId.toString().slice(-1)]
    },
    getNameFile (file) {
      var name = file.split('/')[file.split('/').length - 1]
      return name
    },
    getExtensionFile (file) {
      var extension = file
        .split('/')[file.split('/').length - 1].split('.')[1]
        .toLowerCase()
      return extension
    },
    getImgTypeFile (file) {
      var extensionFile = this.getExtensionFile(file)
      var extensionsTextFile = ['odt', 'txt', 'doc', 'docx']
      var extensionsImgFile = ['jpeg', 'jpg', 'png', 'svg', 'gif']
      var extensionsCalcFile = ['csv', 'ods', 'xls', 'xlt', 'xml']
      var srcImg
      var images = require.context('../assets/i/icons/', false, /\.png$/)

      if (extensionFile === 'pdf') {
        srcImg = images('./pdf.png')
      } else if (extensionsImgFile.includes(extensionFile)) {
        srcImg = images('./img.png')
      } else if (extensionsTextFile.includes(extensionFile)) {
        srcImg = images('./word.png')
      } else if (extensionsCalcFile.includes(extensionFile)) {
        srcImg = images('./excel.png')
      } else {
        srcImg = images('./file.png')
      }
      return srcImg
    }
  },
  created () {
    this.$api
      .port_timetables(this.id)
      .then((response) => {
        this.timetables = response.data
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.$store.commit('token', null)
          this.$router.push('/login')
        }
      })
    this.show = true
    this.$store.commit('pageTitle', 'Lonjas')
    this.$store.commit('pageIcon', 'lonjas.svg')
    this.$gtag.pageview(this.$route)
  }
}
</script>
