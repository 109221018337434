<template>
  <v-main id="page-megaquota-details">
    <v-container fluid>
      <v-layout justify-center>
        <v-flex xs12>
          <div id="megaquota_container">
            <div class="open header">
              <div id="ship_name">{{ getShip().name }}</div>
              <div id="megaquota_name">
                {{ getMegaquota().name }}
              </div>
            </div>
            <div id="megaquota_details_container">
              <div id="megaquota_text">{{ getMegaquota().text }}</div>
              <div id="megaquota_amount">
                {{ formatNumber(getMegaquota().amount) }} Kg
              </div>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'megaquotaDetailView',
  props: {
    megaquotaId: {
      type: [Number, String],
      required: true
    },
    shipId: {
      type: [Number, String],
      required: true
    }
  },
  computed: {
    megaquotas () {
      return this.$store.state.megaquotas
    },
    ships () {
      return this.$store.state.ships
    }
  },
  methods: {
    getMegaquota () {
      return this.megaquotas.find(
        (megaquota) => (megaquota.id === this.megaquotaId)
      )
    },
    getShip () {
      return this.ships.find((ship) => (ship.id === this.shipId))
    },
    formatNumber (number) {
      return number.toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    }
  },
  mounted () {
    this.$store.commit('pageTitle', 'Cuotas')
    this.$store.commit('pageIcon', 'quotas.svg')
  }
}
</script>
