<template>
  <v-main class="page-gestcuota-details">
    <v-container fluid>
      <v-layout justify-center>
        <v-flex xs12>
          <div v-if="gestcuotas.length > 0">
            <div class="open header">
              <div id="ship_name">{{ gestcuotas[0].ship.name }}</div>
              <div id="stock_name">
                {{ gestcuotas[0].stock.name }}
              </div>
            </div>
            <v-tabs v-model="currentTab">
              <v-tab
                v-for="(gestcuota_item, index) in gestcuotas"
                :key="index"
                :href="'#tab-' + gestcuota_item.campaign_year"
                class="v-tab"
              >
                {{ gestcuota_item.campaign_year }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="currentTab">
              <v-tab-item
                v-for="(gestcuota_item, index) in gestcuotas"
                :key="index"
                :value="'tab-' + gestcuota_item.campaign_year"
              >
                <v-card class="open quota-detail noStockGraph">
                  <v-card-text>
                    <div class="quota-detail-data-text">
                      <v-layout>
                        <v-flex xs12>
                          <label
                            v-if="
                              gestcuota_item.monthData[0].adapted_amount > 0
                            "
                            class="card-text"
                          >
                            Total:
                            {{
                              gestcuota_item.monthData[0].adapted_amount
                                | formatNumber
                            }}
                            Kg
                          </label>
                          <label v-else class="card-text">
                            Total: Pendiente de asignar
                          </label>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex xs12>
                          <label class="card-text">
                            Consumida:
                            {{
                              gestcuota_item.monthData[0].consumed
                                | formatNumber
                            }}
                            Kg
                          </label>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex xs12 align-items-right>
                          <label
                            v-if="gestcuota_item.monthData[0].adapted_amount"
                            class="card-text"
                          >
                            Restante:
                            {{
                              gestcuota_item.monthData[0].amount_available
                                | formatNumber
                            }}
                            Kg
                          </label>
                          <label v-else class="card-text"> Restante: - </label>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="quota-detail-data-graph">
                      <v-layout>
                        <v-flex
                          xs12
                          align-items-center
                          class="page-quota-panel-circle"
                        >
                          <v-progress-circular
                            v-if="gestcuota_item.monthData[0].adapted_amount"
                            :size="130"
                            :width="18"
                            :rotate="-90"
                            color="#15BDE6"
                            :value="
                              gestcuota_item.monthData[0].consumed_percent
                            "
                            style="margin-bottom: 15px"
                          >
                            <span class="card-text">
                              {{
                                gestcuota_item.monthData[0].consumed_percent.toFixed(
                                  2
                                )
                              }}%
                            </span>
                          </v-progress-circular>
                          <v-progress-circular
                            v-else
                            :size="130"
                            :width="18"
                            :rotate="-90"
                            color="#15BDE6"
                            :value="0"
                            style="margin-bottom: 15px"
                          >
                            <span class="card-text"> - </span>
                          </v-progress-circular>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'gestcuotaDetailView',
  data () {
    return {
      currentTab: 'tab-' + new Date().getFullYear()
    }
  },
  props: {
    shipId: {
      type: [Number, String],
      required: true
    },
    stockId: {
      type: [Number, String],
      required: true
    }
  },
  computed: {
    gestcuotas () {
      return this.$store.state.gestcuotas
    }
  },
  methods: {
    orderGestcuotasAllYears (x, y) {
      return String(x.campaign_year).localeCompare(String(y.campaign_year))
    }
  },
  created () {
    this.$api
      .gestcuotas(this.shipId, this.stockId)
      .then((response) => {
        let gestcuotaData = response.data.results.sort(
          this.orderGestcuotasAllYears
        )
        this.$store.commit('gestcuotas', gestcuotaData)
      })
      .catch((error) => {
        if (error.response.status === '403') {
          this.$store.commit('token', null)
          this.$router.push('/login')
        }
      })
    this.$store.commit('pageTitle', 'Cuotas')
    this.$store.commit('pageIcon', 'quotas.svg')
  },
  beforeDestroy () {
    this.$store.commit('gestcuotas', '')
  }
}
</script>
