<template>
  <v-main v-if="show" id="page-ventas-ships">
    <v-container fluid fill-height>
      <v-layout>
        <div class="ships">
          <div name="slideInUp" v-for="(ship, index) in ships" :key="index">
            <div class="quotas_ship">
              <v-row class="ship">
                <v-col class="ship_name" v-on:click="goToVentas(ship.id)">{{
                  ship.name
                }}</v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'ventasships',
  components: {},
  data () {
    return {
      show: false,
      title: 'Mis barcos',
      timeout: null
    }
  },
  computed: {
    ships () {
      return this.$store.state.ships
    }
  },
  methods: {
    goToVentas (shipId) {
      this.$router.push({
        name: 'ventas',
        params: {
          shipId: shipId
        }
      })
    },
    sortShips (x, y) {
      return x.name.localeCompare(y.name)
    }
  },
  beforeMount () {
    this.$api.quotas()
      .then(() => {
        if (!this.ships || this.ships.length === 0) {
          this.$router.push({
            name: 'ventas',
            params: {
              shipId: '0'
            }
          })
        } else if (this.ships.length === 1) {
          this.$router.push({
            name: 'ventas',
            params: {
              shipId: this.ships[0].id
            }
          })
        }
      })
      .catch((error) => {
        if (error.response.status === '403') {
          this.$store.commit('token', null)
          this.$router.push('/login')
        }
      })
  },
  mounted () {
    this.show = true
    this.$store.commit('pageTitle', 'Ventas')
    this.$store.commit('pageIcon', 'sales.svg')
  }
}
</script>
