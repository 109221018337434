import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Api from './services/api'
import './assets/scss/app.scss'
import 'vuetify/dist/vuetify.min.css'
import Vuetify from 'vuetify'
import colors from 'vuetify/es5/util/colors'
import numeral from 'numeral'
import 'vue-animate-transitions/dist/vue-animate-transitions.min.css'
import 'material-design-icons/index.js'
import VueGtag from 'vue-gtag'
import moment from 'moment'

Vue.use(VueGtag, {
  config: { id: 'G-GT7WRN2ZPY' }
})

Vue.use(Api, { store })
Vue.use(Vuetify, {
  theme: { disable: true }
})
Vue.config.productionTip = false

numeral.register('locale', 'es', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal: function (number) {
    return number === 1 ? 'º' : 'º'
  },
  currency: {
    symbol: '€'
  },
})
numeral.locale('es')

Vue.filter('formatNumber', (value) => {
  return numeral(value).format('0,0')
})

Vue.filter('formatNumberRounded', (value) => {
  return parseInt(value)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
})

Vue.filter('formatDate', (value) => {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
})

Vue.filter('formatHour', (value) => {
  if (value) {
    return moment('0001-01-01 ' + String(value)).format('HH:mm')
  }
})

Vue.filter('formatSecondDecimal', (value) => {
  if (value != null) {
    return numeral(value).format('0,000.00')
  }
})

new Vue({
  vuetify: new Vuetify(),
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
