<template>
  <div>
    <loading-component></loading-component>
    <v-main id="page-closed-seasons">
      <v-container fluid fill-height>
        <v-layout justify-center align-center>
          <v-flex xs12 sm10>
            <div id="container_closed_seasons">
              <div v-if="closed_seasons && closed_seasons.length > 0">
                <div
                  name="slideInUp"
                  v-for="(closedSeason, index_close_season) in closed_seasons"
                  :key="index_close_season"
                >
                  <v-container class="container_closed_season">
                    <div
                      v-if="statusClosedSeasonCheck(closedSeason)"
                      class="close_circle"
                    >
                      <img
                        src="../assets/i/icons/exclamation.svg"
                        alt="close_circle"
                      />
                    </div>
                    <div class="name_closed_season">{{ closedSeason.name }}</div>
                    <div
                      v-if="closedSeason.comment"
                      class="comment_closed_season"
                    >
                      {{ closedSeason.comment }}
                    </div>
                    <div class="closed_season_dates">
                      <v-row>
                        <v-col cols="3">Estado:</v-col>
                        <v-col
                          v-if="statusClosedSeasonCheck(closedSeason)"
                          cols="9"
                          class="status_closed_season status_closed"
                        >
                          Cerrada
                        </v-col>
                        <v-col v-else cols="9" class="status_closed_season">
                          Abierta
                        </v-col>
                      </v-row>
                      <v-row
                        class="dates_closed_seasons"
                        v-for="(
                          closedSeasonDate, index_close_season_date
                        ) in closedSeason.closed_season_dates"
                        v-bind:class="{
                          status_closed:
                            statusClosedSeasonDateCheck(closedSeasonDate),
                        }"
                        :key="index_close_season_date"
                      >
                        <v-col cols="3">Veda:</v-col>
                        <v-col cols="9" class="dates">
                          <div
                            v-if="closedSeasonDate.comment"
                            class="comment_date"
                          >
                            ({{ closedSeasonDate.comment }})
                          </div>
                          <div class="start_and_end_date">
                            {{ customDateFormat(closedSeasonDate.start_date) }} -
                            {{ customDateFormat(closedSeasonDate.end_date) }}
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-container>
                  <v-divider
                    v-if="index_close_season < closed_seasons.length - 1"
                  ></v-divider>
                </div>
              </div>
              <div v-else>
                <p id="no-vedas">No hay Vedas</p>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import LoadingComponent from '../components/LoadingComponent.vue'

export default {
  name: 'closedSeasonsView',
  components: {
    LoadingComponent
  },
  computed: {
    closed_seasons () {
      return this.$store.state.closed_seasons
    }
  },
  methods: {
    statusClosedSeasonCheck (closedSeason) {
      var activeClosedSeason = false
      closedSeason.closed_season_dates.forEach((closedSeasonDate) => {
        if (this.statusClosedSeasonDateCheck(closedSeasonDate)) {
          activeClosedSeason = true
        }
      })
      if (activeClosedSeason) {
        return true
      } else {
        return false
      }
    },
    statusClosedSeasonDateCheck (closedSeasonDate) {
      var currentYearTime = new Date()
      var currentDate = new Date(
        currentYearTime.getFullYear(),
        currentYearTime.getMonth(),
        currentYearTime.getDate()
      )
      var startDate = new Date(closedSeasonDate.start_date)
      var endDate = new Date(closedSeasonDate.end_date)
      if (currentDate >= startDate && currentDate <= endDate) {
        return true
      } else {
        return false
      }
    },
    customDateFormat (date) {
      var year = date.slice(0, 4)
      var month = date.slice(5, 7)
      var day = date.slice(8, 10)
      date = day + '/' + month + '/' + year
      return date
    }
  },
  created () {
    this.$api
      .closed_seasons()
      .catch((error) => {
        if (error.response.status === 403) {
          this.$store.commit('token', null)
          this.$router.push('/login')
        }
      })
    this.$store.commit('pageTitle', 'Vedas')
    this.$store.commit('pageIcon', 'closed_seasons.svg')
    this.$gtag.pageview(this.$route)
  }
}
</script>
