<template>
  <v-main id="page-quotas-ships">
    <v-container fluid fill-height>
      <v-layout>
        <div class="ships" v-if="show && ships && ships.length > 0">
          <div name="slideInUp" v-for="(ship, index) in ships" :key="index">
            <div
              class="quotas_ship"
              v-bind:class="{ show_quotas: ships.length == 1 }"
            >
              <v-row class="ship" v-on:click="show_quotas">
                <v-col class="ship_name">{{ ship.name }}</v-col>
                <v-col
                  v-if="ships.length > 1"
                  class="col_icon_show_quotas"
                  cols="1"
                >
                  <img
                    src="../assets/i/icons/arrow_down.svg"
                    class="icon_show_quotas"
                  />
                </v-col>
              </v-row>
              <div v-if="(ship.gestcuota_ship && getShipQuotas(ship).length > 0)" class="ship_quotas">
                  <v-container
                    v-for="(cuota, index) in getShipQuotas(ship)"
                    :key="index"
                  >
                    <div
                      class="ship_quota"
                      v-if="cuota.text"
                      v-on:click="goToMegacuota(ship.id, cuota.id)"
                    >
                      {{cuota.name}}
                    </div>
                    <div
                      class="ship_quota"
                      v-else
                      v-on:click="goToGestcuota(ship.id, cuota.id)"
                    >
                      {{cuota.name}}
                    </div>
                  </v-container>
              </div>
              <div v-else class="ship_quotas">
                No hay cuotas asignadas
              </div>
            </div>
          </div>
        </div>
        <v-container class="error_container" v-else-if="show && ships && ships.length <= 0">
          No tienes barcos asignados
        </v-container>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'quotasShipView',
  components: {},
  data () {
    return {
      show: false,
      title: 'Mis barcos'
    }
  },
  computed: {
    ships () {
      return this.$store.state.ships
    },
    megaquotas () {
      return this.$store.state.megaquotas
    }
  },
  methods: {
    getShipQuotas (ship) {
      var quotas = []
      for (let i = 0; i < ship.gestcuota_ship.length; i++) {
        const gestcuota = ship.gestcuota_ship[i]
        if (
          gestcuota.campaign_year === new Date().getFullYear() ||
          gestcuota.campaign_year === new Date().getFullYear() - 1
        ) {
          if (quotas.length < 1) {
            quotas.push(gestcuota.stock)
          } else if (!quotas.find((stock) => stock.id === gestcuota.stock.id)) {
            quotas.push(gestcuota.stock)
          }
        }
      }
      for (let i = 0; i < this.megaquotas.length; i++) {
        let megaquota = this.megaquotas[i]
        if (megaquota.ship_modalitys.includes(ship.type)) {
          quotas = quotas.concat(megaquota)
        }
      }
      quotas.sort(function (a, b) {
        a = a.name.toLowerCase()
        b = b.name.toLowerCase()
        return a < b ? -1 : a > b ? 1 : 0
      })
      return quotas
    },
    goToGestcuota (shipId, stockId) {
      this.$router.push({
        name: 'gestcuota',
        params: {
          shipId: shipId,
          stockId: stockId
        }
      })
    },
    goToMegacuota (shipId, megaquotaId) {
      this.$router.push({
        name: 'megacuota',
        params: {
          megaquotaId: megaquotaId,
          shipId: shipId
        }
      })
    },
    show_quotas (event) {
      if (this.ships.length > 1) {
        document.getElementsByClassName('quotas_ship').forEach((element) => {
          if (event.target.closest('.quotas_ship') !== element) {
            element.classList.remove('show_quotas')
          }
        })
        event.target.closest('.quotas_ship').classList.toggle('show_quotas')
      }
    }
  },
  created () {
    if (
      new Date().getTime() - this.$store.state.lastQuotasUpdate >
      process.env.VUE_APP_QUOTAS_CACHE_TIME * 1000
    ) {
      this.$api
        .quotas()
        .then((response) => {
          this.$store.commit('lastQuotasUpdate', new Date().getTime())
          this.show = true
        })
        .catch((error) => {
          if (error.response.status === '403') {
            this.$store.commit('token', null)
            this.$router.push('/login')
          }
        })
    } else {
      this.show = true
    }
    this.$store.commit('pageTitle', 'Cuotas')
    this.$store.commit('pageIcon', 'quotas.svg')
  }
}
</script>
