<template>
  <v-main v-if="show" id="page-subasta-ports">
    <v-container fluid fill-height>
      <v-layout>
        <div class="ships">
          <div name="slideInUp" v-for="(port, index) in ports" :key="index">
            <div class="quotas_ship">
              <v-row class="ship">
                <v-col
                  class="ship_name"
                  v-on:click="goToSubasta(port.id, port.alias)"
                  >{{ port.alias }}</v-col
                >
              </v-row>
            </div>
          </div>
        </div>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'subastaports',
  components: {},
  data () {
    return {
      show: false,
      title: 'Mis puertos',
      timeout: null
    }
  },
  computed: {
    ports () {
      return this.$store.state.global_auctions_stats_ports.sort(this.sortPorts)
    }
  },
  methods: {
    goToSubasta (portId, portAlias) {
      this.$router.push({
        name: 'subasta',
        params: {
          portId: portId,
          portAlias: portAlias
        }
      })
    },
    sortPorts (x, y) {
      return x.alias.localeCompare(y.alias)
    }
  },
  beforeMount () {
    this.$api.global_auctions_stats_ports()
      .then(() => {
        if (!this.ports || this.ports.length === 0) {
          this.$router.back()
        } else if (this.ports.length === 1) {
          this.$router.push({
            name: 'subasta',
            params: {
              portId: this.ports[0].id,
              portAlias: this.ports[0].alias
            }
          })
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          this.$store.commit('token', null)
          this.$router.push('/login')
        }
      })
  },
  mounted () {
    this.show = true
    this.$store.commit('pageTitle', 'Subasta')
    this.$store.commit('pageIcon', 'auctions.svg')
  }
}
</script>
