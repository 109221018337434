<template>
  <div v-if="provinces != undefined">
    <loading-component></loading-component>
    <v-main class="page-timetables">
      <v-container fluid fill-height>
        <v-layout justify-center align-center>
          <v-flex xs12 sm10>
            <v-container id="container-timetables">
              <div class="container">
                <div
                  class="province"
                  v-for="(province, index) in provinces"
                  :key="index"
                >
                  <v-container class="province-container">
                    <v-row class="province-header" @click="openClose(index)">
                      <v-col cols="10">
                        <div :id="index">{{ province.name }}</div>
                      </v-col>
                      <v-col cols="2">
                        <div class="portArrowContainer">
                          <img v-if="provinces.length > 1" class="portArrow" src="../assets/i/icons/arrow_down.svg"
                            alt="arrow" v-bind:class="{ portArrowOpen: !province.show }" />
                        </div>

                      </v-col>
                    </v-row>
                    <transition name="slide-fade">
                      <div :id="province.name" class="province-ports-wrapper" v-if="province.show">
                        <div class="province-port" v-for="(puerto, index) in timetablePortFromProvince(
                          province.name
                        )" :key="index">
                          <div class="port" :id="puerto.id" @click="gotoTimetable(puerto.id)">
                            <h2>{{ puerto.alias }}</h2>
                          </div>
                        </div>
                      </div>
                    </transition>
                  </v-container>
                </div>

                <v-container class="no_timetables" v-if="provinces.length <= 0">
                  No tienes Lonjas seleccionadas
                </v-container>
                </div>
            </v-container>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </div>

</template>

<script>
import LoadingComponent from '../components/LoadingComponent.vue'

export default {
  name: 'timetables-ports',
  components: {
    LoadingComponent
  },
  data() {
    return {
      show: false,
      title: 'Lonjas',
      timetables: undefined,
      ports: undefined,
      provinces: undefined
    }
  },
  computed: {

  },
  methods: {
    openClose(id) {
      if (this.provinces.length > 1) {
        if (this.provinces[id].show) {
          this.provinces[id].show = false
        } else {
          this.provinces.forEach((element) => {
            element.show = false
          })
          this.provinces[id].show = true
        }
      }
    },

    gotoTimetable (id) {
      this.$router.push({
        name: 'horario',
        params: {
          id
        }
      })
    },
    customDateFormat(day, month, year) {
      var date = '0/00/0000'
      var mesesAbreviados = [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic'
      ]
      var currentYear = new Date().getFullYear()
      if (year === currentYear.toString()) {
        date = day + ' ' + mesesAbreviados[month - 1] + '.'
      } else {
        date = day + '/' + month + '/' + year.slice(2, 4)
      }
      return date
    },
    asignAvatarColor(userId) {
      var colors = [
        '#FF4E00',
        '#6783b6',
        '#397367',
        '#ff66b3',
        '#2c6e49',
        '#222e50',
        '#463239',
        '#721121',
        '#9d8420',
        '#b8336a'
      ]
      return colors[userId.toString().slice(-1)]
    },
    getNameFile(file) {
      var name = file.split('/')[file.split('/').length - 1]
      return name
    },
    getExtensionFile(file) {
      var extension = file
        .split('/')[file.split('/').length - 1].split('.')[1]
        .toLowerCase()
      return extension
    },
    getImgTypeFile(file) {
      var extensionFile = this.getExtensionFile(file)
      var extensionsTextFile = ['odt', 'txt', 'doc', 'docx']
      var extensionsImgFile = ['jpeg', 'jpg', 'png', 'svg', 'gif']
      var extensionsCalcFile = ['csv', 'ods', 'xls', 'xlt', 'xml']
      var srcImg
      var images = require.context('../assets/i/icons/', false, /\.png$/)

      if (extensionFile === 'pdf') {
        srcImg = images('./pdf.png')
      } else if (extensionsImgFile.includes(extensionFile)) {
        srcImg = images('./img.png')
      } else if (extensionsTextFile.includes(extensionFile)) {
        srcImg = images('./word.png')
      } else if (extensionsCalcFile.includes(extensionFile)) {
        srcImg = images('./excel.png')
      } else {
        srcImg = images('./file.png')
      }
      return srcImg
    },

    transformPortsData(ports) {
      let puertosenProvincia = new Map()
      ports.forEach((element) => {
        if (
          puertosenProvincia.get(element.get_province_display) === undefined
        ) {
          puertosenProvincia.set(element.get_province_display, [])
          puertosenProvincia.get(element.get_province_display).push(element)
        } else {
          puertosenProvincia.get(element.get_province_display).push(element)
        }
      })

      this.ports = puertosenProvincia
      return puertosenProvincia
    },
    timetablePortFromProvince: function (province) {
      return this.ports.get(province)
    }
  },

  created() {
    this.$api
      .association_ports()
      .then((response) => {
        this.timetables = response.data

        this.ports = this.transformPortsData(response.data)

        let provinces = Array.from(this.ports.keys()).sort()
        let arrProvinces = []
        var showElement = false
        provinces.forEach((element) => {
          arrProvinces.push({ name: element, show: showElement }
          )
          /* if (showElement) {
            showElement = false
          } */
        })
        if (arrProvinces.length === 1) {
          arrProvinces[0].show = true
        }
        this.provinces = arrProvinces
        return this.provinces
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.$store.commit('token', null)
          this.$router.push('/login')
        }
      })
    this.show = true
    this.$store.commit('pageTitle', 'Lonjas')
    this.$store.commit('pageIcon', 'lonjas.svg')
    this.$gtag.pageview(this.$route)
  }
}
</script>
