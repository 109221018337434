<template>
  <div>
    <loading-component></loading-component>
    <v-main class="page-subasta">
      <v-container fluid fill-height>
        <v-layout justify-center align-center>
          <v-flex xs12 sm10>
            <v-container class="container-subasta">
              <div class="port-alias">
                {{ portAlias }}
              </div>
              <div class="botones-fechas">
                <button v-on:click="load_auctions_rows(-1)">
                  <img
                    src="../assets/i/icons/arrow_down.svg"
                    class="icon-row-left"
                  />
                </button>
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ dateStringCompanion }} {{ dateString | formatDate }}
                    </span>
                  </template>
                  <v-date-picker
                    v-model="dateString"
                    no-title
                    @input="menuDate = false"
                    locale="es-es"
                    :max="today"
                    full-width
                  ></v-date-picker>
                </v-menu>
                <button
                  v-if="dateString < today"
                  v-on:click="load_auctions_rows(1)"
                >
                  <img
                    src="../assets/i/icons/arrow_down.svg"
                    class="icon-row-right"
                  />
                </button>
              </div>
              <div v-if="loadingAuctions" class="loading-auctions">
                <v-progress-circular
                  :size="200"
                  :width="10"
                  color="white"
                  indeterminate
                >
                  Cargando...
                </v-progress-circular>
              </div>
              <div v-else>
                <div v-if="auctions.length > 0">
                  <div class="table-auctions-header">Precio medio</div>
                  <div class="table-auctions-container">
                    <table class="table-auctions">
                      <tr
                        v-for="(auction, index_auction) in auctions"
                        :key="index_auction"
                        class="table-row"
                      >
                        <td class="col-name">
                          {{ auction.specie.association_name }}
                        </td>
                        <td class="col-price">
                          {{ auction.price | formatSecondDecimal }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div v-else class="no-auctions">No hay datos</div>
              </div>
            </v-container>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import LoadingComponent from '../components/LoadingComponent.vue'

export default {
  name: 'subastaView',
  components: {
    LoadingComponent
  },
  data () {
    return {
      show: false,
      title: 'Subasta',
      date: new Date(),
      dateString: null,
      dateStringCompanion: '',
      menuDate: false,
      arrowControl: true,
      loadingAuctions: false,
      timer: null
    }
  },
  props: {
    portId: {
      type: [Number, String],
      required: true
    },
    portAlias: {
      type: [Number, String],
      required: true
    }
  },
  watch: {
    dateString () {
      this.load_auctions()
    }
  },
  computed: {
    auctions () {
      let auctions = this.$store.state.global_auctions_stats
      return auctions
        .sort(this.sortAuctionsName)
        .sort(this.sortAuctionsPrice)
        .reverse()
    },
    today () {
      return this.format_date(new Date())
    },
    yesterday () {
      return new Date(new Date().setDate(new Date().getDate() - 1))
    }
  },
  methods: {
    PadLeft (value, length) {
      return value.toString().length < length
        ? this.PadLeft('0' + value, length)
        : value
    },
    format_date (localDate) {
      return (
        this.PadLeft(localDate.getFullYear(), 2) +
        '-' +
        this.PadLeft(localDate.getMonth() + 1, 2) +
        '-' +
        this.PadLeft(localDate.getDate(), 2)
      )
    },
    load_auctions_rows (x) {
      if (this.arrowControl) {
        this.arrowControl = false
        this.date.setDate(this.date.getDate() + x)
        this.dateString = this.format_date(this.date)
        setTimeout(() => {
          this.arrowControl = true
        }, 100)
      }
    },
    sortAuctionsPrice (x, y) {
      return x.price - y.price
    },
    sortAuctionsName (x, y) {
      return x.specie.association_name.localeCompare(y.specie.association_name)
    },
    load_auctions () {
      this.loadingAuctions = true
      let dateStringControl = this.dateString
      this.date = new Date(this.dateString)
      if (this.format_date(this.date) === this.today) {
        this.dateStringCompanion = 'HOY'
      } else if (this.format_date(this.date) === this.format_date(this.yesterday)) {
        this.dateStringCompanion = 'AYER'
      } else {
        this.dateStringCompanion = 'DÍA'
      }
      setTimeout(() => {
        if (dateStringControl === this.dateString && this.portId) {
          this.$api
            .global_auctions_stats(this.dateString, this.portId)
            .then(() => {
              this.loadingAuctions = false
              clearInterval(this.timer)
              if (this.format_date(this.date) === this.today) {
                this.timer = setInterval(this.load_auctions, 30000)
              }
            })
            .catch((error) => {
              if (error.response.status === 403) {
                this.$store.commit('token', null)
                this.$router.push('/login')
              }
            })
        }
      }, 500)
    }
  },
  beforeMount () {
    this.dateString = this.format_date(this.date)
  },
  mounted () {
    this.show = true
    this.$store.commit('pageTitle', 'Subasta')
    this.$store.commit('pageIcon', 'auctions.svg')
    this.$gtag.pageview(this.$route)
    document.addEventListener('visibilitychange', this.load_auctions, true)
  },
  destroyed () {
    // delete all timeouts
    const timeoutsId = window.setTimeout(() => {}, Number.MAX_SAFE_INTEGER)
    for (let i = 1; i < timeoutsId; i++) {
      window.clearTimeout(i)
    }
    // delete all intervals
    const intervalsId = window.setInterval(() => {}, Number.MAX_SAFE_INTEGER)
    for (let i = 1; i < intervalsId; i++) {
      window.clearInterval(i)
    }
    document.removeEventListener('visibilitychange', this.load_auctions, true)
  }
}
</script>
