<template>
  <v-main class="page-tallas">
    <v-container fluid fill-height>
       <div v-if="tallas_total.length <= 0" class="no-tallas"><p>No hay datos de tallas</p></div>
      <v-layout v-else justify-center align-center>
        <v-flex class="page-tallas-panel" xs12 sm10>
          <v-tabs v-model="currentTable">
            <v-tab
              v-if="tallas_cadiz.length > 0"
              :href="'#tab-cadiz'"
              class="v-tab-cadiz"
            >
              CANT. NO./ G.CÁDIZ
            </v-tab>
            <v-tab
              v-if="tallas_medit.length > 0"
              :href="'#tab-medit'"
              class="v-tab-medit"
            >
              MEDIT.
            </v-tab>
            <v-tab
              v-if="tallas_canario.length > 0"
              :href="'#tab-canario'"
              class="v-tab-canario"
            >
              C. CANARIO
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="currentTable"

          >
            <v-tab-item :value="'tab-cadiz'">
              <v-card flat height="80vh">
                <v-card-text>
                  <table>
                    <thead>
                      <tr class="page-tallas-table-head">
                        <td class="page-tallas-row-name"></td>
                        <td class="page-tallas-row-num">Cm</td>
                        <td class="page-tallas-row-num">Kg</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="page-tallas-row-item"
                        v-for="(talla, index) in tallas_cadiz"
                        :key="index"
                      >
                        <td class="page-tallas-row-name">
                          {{ talla.specie_name_association }}
                        </td>
                        <td class="page-tallas-row-num">{{ talla.size_cm }}</td>
                        <td class="page-tallas-row-num">{{ talla.size_kg }}</td>
                      </tr>
                    </tbody>
                  </table>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item :value="'tab-medit'">
              <v-card flat height="80vh">
                <v-card-text>
                  <table>
                    <thead>
                      <tr class="page-tallas-table-head">
                        <td class="page-tallas-row-name"></td>
                        <td class="page-tallas-row-num">Cm</td>
                        <td class="page-tallas-row-num">Kg</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="page-tallas-row-item"
                        v-for="(talla, index) in tallas_medit"
                        :key="index"
                      >
                        <td class="page-tallas-row-name">
                          {{ talla.specie_name_association }}
                        </td>
                        <td class="page-tallas-row-num">{{ talla.size_cm }}</td>
                        <td class="page-tallas-row-num">{{ talla.size_kg }}</td>
                      </tr>
                    </tbody>
                  </table>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item :value="'tab-canario'">
              <v-card flat height="80vh">
                <v-card-text>
                  <table>
                    <thead>
                      <tr class="page-tallas-table-head">
                        <td class="page-tallas-row-name"></td>
                        <td class="page-tallas-row-num">Cm</td>
                        <td class="page-tallas-row-num">Kg</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="page-tallas-row-item"
                        v-for="(talla, index) in tallas_canario"
                        :key="index"
                      >
                        <td class="page-tallas-row-name">
                          {{ talla.specie_name_association }}
                        </td>
                        <td class="page-tallas-row-num">{{ talla.size_cm }}</td>
                        <td class="page-tallas-row-num">{{ talla.size_kg }}</td>
                      </tr>
                    </tbody>
                  </table>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'tallas',
  data () {
    return {
      tallas_data: {},
      currentTable: 'tab-cadiz'
    }
  },
  computed: {
    tallas_cadiz () {
      let tallasCadiz = []
      Array.from(this.tallas_total).forEach(function (valor) {
        if (valor.zone === 'Cant. NO./ G. Cádiz') {
          tallasCadiz.push(valor)
        }
      })
      return tallasCadiz
    },
    tallas_medit () {
      let tallasMedit = []
      Array.from(this.tallas_total).forEach(function (valor) {
        if (valor.zone === 'Mediterráneo') {
          tallasMedit.push(valor)
        }
      })
      return tallasMedit
    },
    tallas_canario () {
      let tallasCanario = []
      Array.from(this.tallas_total).forEach(function (valor) {
        if (valor.zone === 'Canario') {
          tallasCanario.push(valor)
        }
      })
      return tallasCanario
    },
    tallas_total () {
      if (this.$store.state.sizes.length !== undefined) {
        return this.$store.state.sizes
      } else {
        return this.tallas_data
      }
    }
  },
  methods: {},
  mounted () {
    this.$api
      .sizes()
      .then((response) => {
        this.tallas_data = response.data.results
      })
      .catch((error) => {
        if (error.response.status === '403') {
          this.$store.commit('token', null)
          this.$router.push('/login')
        }
      })
    this.$store.commit('pageTitle', 'Tallas')
    this.$store.commit('pageIcon', 'tallas.svg')
  }
}
</script>
